<template>
  <div class="w-full h-full flex flex-row">
    <div
      v-if="toast"
      class="rounded-lg text-white font-bold px-6 py-3 w-56 fixed bottom-5 right-5 z-50 transition-transform transform translate-y-0"
      :class="toastSuccess ? 'bg-green-600' : 'bg-red-500'"
    >
      {{ toast }}
    </div>
    <Navigation
      :title="route"
      ref="nav"
      :cards="cards"
      :showMealPlanning="showMealPlanning"
      :pickupContracts="pickupContracts"
    />
    <div class="flex flex-grow flex-col relative">
      <Header :title="route" @toggleMenu="toggleMenu" />
      <div id="content" class="w-full h-full overflow-y-auto">
        <div class="w-full h-full p-4">
          <Cards v-if="route === 'Home' && cards.length > 0 || route === 'Karten'" :cards="cards" />
          <Mealplanning
            v-else-if="route === 'Essensplanung'"
            @toast="showToast"
          />
          <Pickup
            v-else-if="route === 'Abholung'"
            @toast="showToast"
            :contracts="pickupContracts"
          />
          <div
            v-else-if="loaded"
            class="font-bold w-full bg-white rounded-lg p-4"
          >
            <span
              >Sie können hier nichts tun. Melden sie sich mit einem
              Unteraccount an oder buchen Sie eine Leistung</span
            >
          </div>
          <div v-else class="font-bold w-full bg-white rounded-lg p-4">
            <span>Lädt...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Cards from "@/components/Cards.vue";
import Header from "@/components/Header.vue";
import Navigation from "@/components/Navigation.vue";
import Mealplanning from "@/components/Mealplanning.vue";
import Pickup from "@/components/Pickup.vue";
import API from "@/API";
export default {
  name: "HomeView",
  components: {
    Navigation,
    Header,
    Cards,
    Mealplanning,
    Pickup,
  },
  data() {
    return {
      toast: "",
      route: this.$router.currentRoute.value.name,
      cards: [],
      pickupContracts: [],
      eatingContracts: [],
      showMealPlanning: false,
      loaded: false,
    };
  },
  methods: {
    getCards() {
      API.getCards((r, status) => {
        if (status === 200) {
          this.cards = r;
          if (
            this.$router.currentRoute.value.name == "Home" &&
            this.cards.length > 0
          ) {
            window.location = "/cards";
          }
        }
        this.loaded = true;
        this.getEatingContracts();
      });
    },
    getEatingContracts() {
      let vm = this;
      API.getEatingContracts((r, status) => {
        if (status === 200) {
          if (r.length > 0) {
            vm.showMealPlanning = true;
            if (
              this.$router.currentRoute.value.name == "Home" &&
              this.showMealPlanning
            ) {
              window.location = "/mealplanning";
            }
          }
        }
        this.getPickupContracts();
      });
    },
    getPickupContracts() {
      let vm = this;
      API.getPickupContracts((r, status) => {
        if (status === 200) {
          vm.pickupContracts = r;
          if (this.$router.currentRoute.value.name == "Home" && r.length > 0) {
            window.location = "/pickup";
          }
        }
      });
    },
    showToast(toast, success = true) {
      this.toast = toast;
      this.toastSuccess = success;
      setTimeout(() => {
        this.toast = "";
      }, 3000);
    },
    toggleMenu() {
      this.$refs.nav.toggle(true);
    },
  },
  created() {
    setTimeout(() => {
      this.getCards();
    }, 500);
  },
};
</script>
