<template>
  <div class="relative w-full h-full overflow-y-auto">
    <div
      class="bg-black/50 fixed top-20 left-56 right-0 h-full text-center p-10"
      v-if="editedMeal"
    >
      <div
        class="bg-white rounded-lg p-4 max-h-full overflow-y-auto inline-block relative text-left"
      >
        <div
          class="absolute top-5 right-5 p-2 cursor-pointer"
          @click="editedMeal = false"
        >
          <font-awesome-icon icon="xmark" class="text-2xl" />
        </div>
        <span class="font-bold text-xl w-full text-center">Essen anlegen</span>
        <label>Hauptgericht</label>
        <textarea
          class="border-2 border-gray-200 px-2 py-1 w-96"
          v-model="editableMeal"
          placeholder="Gericht"
          @input="updateMeals()"
        ></textarea
        ><br />
        <span class="font-bold">Fleischart: </span>
        <select v-model="editableMealType" class="my-2">
          <option value="Vegetarisch">Vegetarisch</option>
          <option value="Geflügel">Geflügel</option>
          <option value="Fisch">Fisch</option>
          <option value="Rind">Rind</option>
          <option value="Schwein">Schwein</option>
          <option value="Lamm">Lamm</option>
        </select>
        <label>Variationen (mit "/" getrennt)</label>
        <input
          placeholder="Mit Vollkorn/Ohne Vollkorn"
          class="border-2 border-gray-200 px-2 py-1 w-96"
          v-model="editableVariation"
        />
        <div class="w-full mt-2">
          <button @click="saveMeal()">Speichern</button>
        </div>
        <div class="">
          <div
            v-for="meal in freemeals"
            @click="
              editableMeal = meal;
              updateMeals();
            "
            :key="meal"
            class="cursor-pointer border-2 border-gray-200 inline-block shadow-lg px-2 py-1 m-1"
          >
            {{ meal }}
          </div>
          <div
            v-for="meal in usedmeals"
            @click="
              editableMeal = meal;
              updateMeals();
            "
            :key="meal"
            class="cursor-pointer border-2 border-gray-200 bg-gray-500 inline-block shadow-lg px-2 py-1 m-1"
          >
            {{ meal }}
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-lg py-4 pl-4 w-full">
      <span class="font-bold text-xl">Essensbestellung für Vertrag:</span><br />
      <div
        v-for="contract in contracts"
        :key="contract.id"
        class="mr-4 shadow-lg border-4 rounded-lg my-2 px-2 pb-2 text-center inline-block align-top cursor-pointer"
        :class="{ 'border-green-500': currentContract == contract }"
        @click="selectContract(contract)"
      >
        <span class="font-bold text-lg whitespace-nowrap">{{
          contract.project
        }}</span>
        <div class="w-full text-left">
          <span><b>Name: </b>{{ contract.name }}</span>
        </div>
      </div>
      <label>Kalenderwoche</label>

      <button
        v-for="availableWeek in availableWeeks"
        :key="availableWeek"
        class="font-bold px-2 py-1 border-gray-400 border-2 rounded-lg mr-2 mt-2 inline-block hover:border-green-200 hover:bg-transparent hover:text-black cursor-pointer text-black"
        :class="{ '!border-green-400': currentWeek == availableWeek }"
        @click="setWeek(availableWeek)"
      >
        KW {{ availableWeek }} ({{
          getWeekDates(new Date().getFullYear(), availableWeek)
        }})
      </button>
    </div>

    <div
      v-if="availableWeeks.length == 0"
      class="bg-white rounded-lg p-4 mt-4 font-bold inline-block w-full"
    >
      <span>Keine Essenspläne für diesen Vertrag verfügbar</span>
    </div>
    <div v-if="availableWeeks.length > 0">
      <div
        class="bg-white rounded-lg py-4 px-4 w-full mt-2"
        v-if="Object.keys(week).length > 0"
      >
        <span class="font-bold text-xl"
          >KW {{ currentWeek }} ({{ getWeekDates(year, currentWeek) }})</span
        >
        <div
          v-for="day in Object.keys(week)"
          :key="day"
          class="border-b border-black"
        >
          <div v-if="currentContract[day]">
            <span class="font-bold"
              >{{ day }},
              {{ getWeekDateForWeekday(year, currentWeek, day) }}</span
            >
            <div v-if="week[day].closed">
              <span class="inline-block ml-10 py-2 font-bold"
                >Mensa geschlossen: {{ week[day].closingReason }}</span
              >
            </div>
            <div v-else-if="!currentContract.allergy">
              <div
                v-for="meal in week[day].meals"
                :key="meal"
                class="inline-block"
              >
                <div
                  class="shadow-lg p-2 rounded-xl m-2 cursor-pointer max-w-72 inline-block border-2"
                  :class="
                    week[day].choices.main &&
                    week[day].choices.main === meal.main
                      ? 'border-green-600'
                      : ''
                  "
                  v-if="
                    meal.main != '' &&
                    (editMode ||
                      (week[day].choices.main &&
                        week[day].choices.main === meal.main))
                  "
                >
                  <div
                    class="w-full h-full flex flex-row"
                    @click="chooseMeal(day, meal)"
                  >
                    <font-awesome-icon
                      v-if="meal.mainType === 'Schwein'"
                      icon="piggy-bank"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <font-awesome-icon
                      v-else-if="meal.mainType === 'Rind'"
                      icon="cow"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <font-awesome-icon
                      v-else-if="meal.mainType === 'Fisch'"
                      icon="fish-fins"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <font-awesome-icon
                      v-else-if="meal.mainType === 'Geflügel'"
                      icon="drumstick-bite"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <img
                      src="@/assets/lamb.png"
                      v-else-if="meal.mainType === 'Lamm'"
                      class="text-secondary align-top mt-1 pr-2 w-12"
                    />
                    <font-awesome-icon
                      v-else-if="meal.mainType === 'Vegetarisch'"
                      icon="leaf"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <font-awesome-icon
                      v-else
                      icon="utensils"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <div class="inline-block">
                      <div class="border-b border-gray-700">
                        <span
                          class="font-bold"
                          @click="chooseMeal(day, meal)"
                          >{{ meal.main }}</span
                        >
                      </div>
                      <div>
                        <div v-if="meal.variations.length > 0" class="pt-2">
                          <div
                            v-for="variation in meal.variations"
                            :key="variation"
                            class="inline-block"
                          >
                            <div
                              v-if="
                                editMode ||
                                week[day].choices.variation == variation
                              "
                              @click="chooseVariation(day, variation)"
                              :class="
                                week[day].choices.main &&
                                week[day].choices.main === meal.main &&
                                week[day].choices.variation == variation
                                  ? 'border-green-600'
                                  : ''
                              "
                              class="font-bold px-2 py-1 m-1 border-2 bg-gray-100 rounded-lg inline-block"
                            >
                              {{ variation }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span
                v-if="week[day].meals[0].main == ''"
                class="inline-block ml-10"
                >Keine Gerichte auswählbar</span
              >
              <div
                class="inline-block shadow-lg p-2 rounded-xl m-2 cursor-pointer align-top"
                v-if="week[day].desserts.length > 0"
              >
                <font-awesome-icon
                  icon="ice-cream"
                  class="text-secondary align-top mt-2 text-4xl"
                />
                <div class="inline-block">
                  <div>
                    <div class="pt-2">
                      <div
                        v-for="dessert in week[day].desserts"
                        :key="dessert"
                        class="inline-block"
                      >
                        <div
                          v-if="
                            editMode || week[day].choices.dessert === dessert
                          "
                          class="font-bold px-2 py-1 m-1 border-2 bg-gray-100 rounded-lg inline-block"
                          :class="
                            week[day].choices.dessert === dessert
                              ? 'border-green-500'
                              : ''
                          "
                          @click="chooseDessert(day, dessert)"
                        >
                          {{ dessert }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <span class="text-red-500 font-bold"
            >Allergikeressen (automatisch ausgewählt)</span
          >
          </div>
          </div>
        </div>
        <div v-if="!currentContract.allergy" class="mt-4 w-full text-center">
          <button
            class="w-96 border-red-400 text-red-400"
            @click="editMode = true"
            v-if="isEditable(currentWeek) && editMode == 0"
          >
            Woche bearbeiten
          </button>
          <button
            class="w-96"
            @click="saveWeek(week)"
            v-else-if="isEditable(currentWeek) && editMode"
          >
            Woche speichern
          </button>
          <span v-else class="text-red-500 font-bold"
            >Auswahl kann nicht mehr geändert werden.</span
          >
        </div>
        
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  display: block;
  font-weight: bold;
}
table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #e6e6e6;
  /* Set the background color for even rows */
}
</style>
<script>
// @ is an alias to /src
import API from "@/API";
export default {
  name: "Mealplanning",
  data() {
    return {
      user: false,
      editMode: false,
      currentContract: {},
      freemeals: [],
      usedmeals: [],
      imported: {
        meals: [],
        desserts: [],
      },
      year: 0,
      project: "",
      contracts: [],
      editedDesserts: false,
      editableDesserts: "",
      editableMeal: "",
      editableMealType: "Vegetarisch",
      editableVariation: "",
      editedMeal: false,
      calendarweek: 0,
      week: {},
      selectedFile: null,
      currentWeek: 0,
      availableWeeks: [],
    };
  },

  methods: {
    chooseDessert(day, dessert) {
      if (!this.editMode) return;
      if (this.week[day].choices.dessert == dessert) {
        this.week[day].choices.dessert = "";
      } else {
        this.week[day].choices.dessert = dessert;
      }
    },
    getUser() {
      API.checkAuth((r, status) => {
        if (status === 200) {
          this.user = r;
        }
      });
    },
    chooseMeal(day, meal) {
      if (!this.editMode) return;
      console.log("Setting" + meal.main);
      if (this.week[day].choices.main === meal.main) {
        return;
      }
      this.week[day].choices.main = meal.main;
      console.log(this.week[day].defaults.main);
      if (this.week[day].defaults.main == meal.main) {
        console.log("setting var");
        this.week[day].choices.variation = this.week[day].defaults.variation;
      } else {
        if (meal.variations.length > 0) {
          this.week[day].choices.variation = meal.variations[0];
        } else {
          this.week[day].choices.variation = "";
        }
      }
    },
    chooseVariation(day, variation) {
      if (!this.editMode) return;
      this.week[day].choices.variation = variation;
    },
    selectContract(contract) {
      this.currentContract = contract;
      let vm = this;
      vm.week = {};
      this.availableWeeks = [];
      for (let i = 0; i < 6; i++) {
        setTimeout(() => {
          vm.getMealPlan(this.calendarweek + i, () => {
            vm.availableWeeks.push(vm.calendarweek + i);
          });
        }, 200 * i);
      }
      let checkWeeksInterval = setInterval(() => {
        if (vm.availableWeeks.length != 0) {
          clearInterval(checkWeeksInterval);
          vm.currentWeek = vm.availableWeeks[0];
          vm.getMealPlan();
        }
      });
    },
    setWeek(week) {
      this.currentWeek = week;
      this.getMealPlan();
    },
    saveWeek(week) {
      this.editMode = false;
      let vm = this;
      let choices = {};
      for (let day in week) {
        choices[day] = week[day].choices;
      }
      API.saveMealPlanForWeek(
        this.currentContract.id,
        this.year,
        this.currentWeek,
        choices,
        (r, status) => {
          if (status === 200) {
            vm.$emit("toast", "Woche gespeichert!");
          }
        }
      );
    },
    getWeekDates(year, weekNumber) {
      const startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 4);

      const startDateStr = startDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
      });
      const endDateStr = endDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
      });

      return `${startDateStr} - ${endDateStr}`;
    },
    getWeekDateForWeekday(year, weekNumber, weekday) {
      let startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7);
      let offset = [
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
      ].indexOf(weekday);
      startDate.setDate(startDate.getDate() + offset);
      console.log(startDate);
      return startDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
      });
    },
    isEditable(week) {
      if (this.user && this.user.impersonated) return true;
      if (week == this.calendarweek) return false;
      if (week - this.calendarweek == 1) {
        let date = new Date();
        if(this.currentContract.project.includes("Schaurte")) {
          if (date.getDay() > 3 || (date.getDay() == 3 && date.getHours() >= 12)) {
            return false;
          }
        }
        else if (date.getDay() != 1 || date.getHours() >= 10) {
          return false;
        }
      }
      return true;
    },
    getWeekOfYear() {
      let date = new Date();
      let dayOfWeek = date.getDay();
      let daysToAdd = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
      let firstDayOfWeek = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + daysToAdd
      );
      console.log(firstDayOfWeek);
      let firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      let days =
        Math.floor((firstDayOfWeek - firstDayOfYear) / (24 * 60 * 60 * 1000)) +
        1;
      this.calendarweek = Math.floor(days / 7) + 1;
      if (this.currentWeek == 0) this.currentWeek = this.calendarweek;
    },
    getMealPlan(weekInQuestion = this.currentWeek) {
      if (!this.currentContract.id) return;
      let vm = this;
      API.getMealPlanForWeek(
        this.currentContract.id,
        this.year,
        weekInQuestion,
        (r, status) => {
          if (status === 200) {
            let anyMeal = false;
            Object.keys(r).forEach((day) => {
              if (r[day].meals[0].main != "") {
                anyMeal = true;
              }
              if (Object.keys(r[day].choices) == 0) {
                r[day].choices = JSON.parse(JSON.stringify(r[day].defaults));
              }
            });
            if (!anyMeal) {
              r = {};
            } else {
              if (!this.availableWeeks.includes(weekInQuestion)) {
                if (vm.availableWeeks.length == 0) {
                  vm.currentWeek = weekInQuestion;
                }
                vm.availableWeeks.push(weekInQuestion);
              }
              if (vm.currentWeek == weekInQuestion) {
                vm.week = r;
              }
            }
          } else if (status === 404) {
            r = {};
          }
        }
      );
    },
    getContracts() {
      let vm = this;
      API.getEatingContracts((r, status) => {
        if (status === 200) {
          vm.contracts = r;
          if (vm.contracts.length > 0) {
            vm.selectContract(vm.contracts[0]);
          }
        }
      });
    },
  },
  created() {
    this.getContracts();
    this.getWeekOfYear();
    this.getUser();
    this.year = new Date().getFullYear();
  },
};
</script>