<template>
  <div class="relative w-full h-full overflow-y-auto">
    <div class="bg-white rounded-lg py-4 pl-4 w-full">
      <span class="font-bold text-xl">Übersicht über Anfragen:</span><br />
      <span class="font-bold" v-if="requests.length == 0"
        >Bisher keine Anfragen</span
      >
      <table v-else>
        <thead>
          <tr>
            <th>Vertrag</th>
            <th>Tag</th>
            <th>Neue Uhrzeit</th>
            <th>Begründung</th>
            <th>Status</th>
            <th>Kommentar</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="request in requests"
            :key="request.id"
          >
            <td>{{ request.contract }} ({{request.name}})</td>
            <td>{{ request.day }}</td>
            <td>{{ request.newTime }}</td>
            <td class="max-w-52">{{ request.reason }}</td>
            <td>{{ request.status }}</td>
            <td>{{ request.comment }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bg-white rounded-lg py-4 pl-4 w-full mt-4">
      <span class="font-bold text-xl">Änderungen für Vertrag anfragen:</span
      ><br />
      <div
        v-for="contract in contracts"
        :key="contract.id"
        class="mr-4 shadow-lg border-4 rounded-lg my-2 px-2 pb-2 text-center inline-block align-top cursor-pointer"
        :class="{ 'border-green-500': currentContract == contract }"
        @click="selectContract(contract)"
      >
        <span class="font-bold text-lg whitespace-nowrap">{{
          contract.project
        }}</span>
        <div class="w-full text-left">
          <span><b>Name: </b>{{ contract.name }}</span>
        </div>
      </div>
    </div>

    <div
      v-if="currentContract.perWeekDay"
      class="bg-white rounded-lg py-4 pl-4 w-full mt-4"
    >
      <span class="font-lg font-bold block"
        >Abholzeit für Wochentag festlegen</span
      >
      <div
        v-for="weekday in [
          'Montag',
          'Dienstag',
          'Mittwoch',
          'Donnerstag',
          'Freitag',
        ]"
        :key="weekday"
      >
        <div v-if="currentContract[weekday]">
          <span class="font-bold">{{ weekday }}s:</span>
          <input
            class="ml-4 inline-block"
            type="time"
            v-model="currentContract[weekday + 'Abholzeit']"
          />
          <button
          v-if="currentContract[weekday + 'Abholzeit']"
            class="mt-2 inline-block ml-2"
            @click="requestWeekDay(weekday)"
          >
            Änderung anfragen
          </button>
          <button
          v-else
            class="mt-2 inline-block ml-2"
            @click="requestWeekDay(weekday)"
          >
            Änderung eintragen
          </button>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-lg py-4 pl-4 w-full mt-4">
      <span class="font-lg font-bold block"
        >Abholzeit für einzelnen Wochentag ändern</span
      >
      <div class="flex flex-col justify-center align-middle">
        <div class="text-left font-bold">
          <span class="align-top pt-5 mr-2 inline-block">Für den</span>
          <input
            class="align-top pt-5 font-normal"
            type="date"
            v-model="singleDay.day"
          />
          <span class="align-top pt-5 mr-2 inline-block"
            >soll die Abholzeit auf</span
          >
          <input
            class="align-top pt-5 font-normal"
            type="time"
            v-model="singleDay.newTime"
          />
          <span class="align-top pt-5 mr-2 inline-block"
            >Uhr geändert werden, da</span
          >
          <textarea
            class="bg-gray-100 font-normal p-2"
            rows="2"
            v-model="singleDay.reason"
          ></textarea
          >.
          <button class="align-top mt-4" @click="requestSingleDay()">
            Änderung anfragen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  display: block;
  font-weight: bold;
}
table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #e6e6e6;
  /* Set the background color for even rows */
}
</style>
<script>
// @ is an alias to /src
import API from "@/API";
export default {
  name: "Mealplanning",
  data() {
    return {
      requests: [],
      contracts: [],
      currentContract: {},
      toast: "",
      toastSuccess: false,
      singleDay: {
      },
    };
  },

  methods: {
    chooseDessert(day, dessert) {
      if (!this.editMode) return;
      if (this.week[day].choices.dessert == dessert) {
        this.week[day].choices.dessert = "";
      } else {
        this.week[day].choices.dessert = dessert;
      }
    },
    chooseMeal(day, meal) {
      if (!this.editMode) return;
      console.log("Setting" + meal.main);
      if (this.week[day].choices.main === meal.main) {
        return;
      }
      this.week[day].choices.main = meal.main;
      console.log(this.week[day].defaults.main);
      if (this.week[day].defaults.main == meal.main) {
        console.log("setting var");
        this.week[day].choices.variation = this.week[day].defaults.variation;
      } else {
        if (meal.variations.length > 0) {
          this.week[day].choices.variation = meal.variations[0];
        } else {
          this.week[day].choices.variation = "";
        }
      }
    },
    requestSingleDay(){
      let vm = this;
      API.requestPickupChange({
        day: this.singleDay.day,
        newTime: this.singleDay.newTime,
        reason: this.singleDay.reason,
        contract: this.currentContract.id,
        type: "single"
      }, (r, status) => {
        if (status === 200) {
          vm.$emit("toast", "Anfrage gesendet!");
          vm.getRequests()
        }
      });
    },
    requestWeekDay(weekday){
      let vm = this;
      API.requestPickupChange({
        weekday: weekday,
        newTime: this.currentContract[weekday + "Abholzeit"],
        contract: this.currentContract.id,
        type: "weekday"
      }, (r, status) => {
        if (status === 200) {
          vm.$emit("toast", "Anfrage gesendet!");
          vm.getRequests()
        }
      });
    },
    selectContract(contract) {
      this.currentContract = contract;
    },

    getRequests() {
      let vm = this;
      API.getPickupChangeRequests((r, status) => {
        if (status === 200) {
          vm.requests = r;
        }
      });
    },
    getContracts() {
      let vm = this;
      API.getPickupContracts((r, status) => {
        if (status === 200) {
          vm.contracts = r;
          if (vm.contracts.length > 0) {
            vm.selectContract(vm.contracts[0]);
          }
        }
      });
    },
  },
  created() {
    this.getContracts();
    this.getRequests();
    this.year = new Date().getFullYear();
  },
};
</script>