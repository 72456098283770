import { get, post } from "./lib/http";

function getMailCode(mail, cb) {
    post("/auth/mail/code", { mail: mail, requestId: generateRequestId(mail) }, cb)
}

function usesPasswordLogin(mail, cb){
    post("/auth/usesPasswordLogin", { mail: mail }, cb)
}

function oidcLogin(provider, code, cb) {
    post("/auth/login", { provider: provider, code: code, redirectOrMail: process.env.VUE_APP_FE_URL + "/login" }, cb)
}
function mailCodeLogin(mail, code, cb) {
    post("/auth/login", { provider: "mailcode", code: code, redirectOrMail: mail }, cb)
}
function mailPasswordLogin(mail, password, cb) {
    post("/auth/login", { provider: "mailpassword", password: password, redirectOrMail: mail }, cb)
}
function checkAuth(cb) {
    get("/auth", cb)
}
function getCards(cb) {
    get("/cards", cb)
}
function checkRFID(data, cb) {
    post("/rfidcheck", data, cb, false)
}
function logout(cb) {
    post("/auth/logout", {}, cb)
}

function getMealPlanForWeek(contract, year,cw,cb){
    get("/contracts/"+contract+"/mealplan/"+year+cw,cb)
}

function saveMealPlanForWeek(contract, year,cw,data,cb){
    post("/contracts/"+contract+"/mealplan/"+year+cw,data,cb)
}

function getQRCode(id, cb) {
    get("/cards/" + id+"/qr", cb)
}

function getEatingContracts(cb) {
    get("/mealplan/contracts", cb)
}

function getPickupContracts(cb) {
    get("/attendance/contracts", cb)
}

function getMealPlanForMonitor(project,cb){
    get("/mealplan/forMonitor?project="+project, cb)
}

function getContractsWithName(project, name, cb){
    var req = new XMLHttpRequest()
    req.open("GET", process.env.VUE_APP_BE_URL + "/rest/contracts?project="+project+"&name="+name)
    req.setRequestHeader('content-type', 'application/json')
    req.setRequestHeader('X-Auth-Token', 'Kvq10g5nB2V4Q9cYsZ7hzG3D9e2wXaTf4P8kIuM6xV3RlYoNjQ8vU7mC4bXw2p0O3n1S6a5z')
    req.withCredentials = true
    req.addEventListener('load', function (event) {
        try {
            let response = JSON.parse(req.responseText)
            cb(response, req.status)
        } catch (e) {
            cb(false, req.status)
        }
    })
    req.onerror = function (event) {
        cb(false, false)
    }
    req.send()
}

function requestPickupChange(change, cb) {
    post("/attendance/pickupChange", change, cb)
}

function getPickupChangeRequests(cb) {
    get("/attendance/requests", cb)
}

export default {
    getMailCode,
    oidcLogin,
    mailCodeLogin,
    checkAuth,
    getCards,
    checkRFID,
    logout,
    usesPasswordLogin,
    mailPasswordLogin,
    getQRCode,
    getMealPlanForWeek,
    getEatingContracts,
    saveMealPlanForWeek,
    getContractsWithName,
    getPickupContracts,
    requestPickupChange,
    getPickupChangeRequests,
    getMealPlanForMonitor
}

function generateRequestId(message) {
    let hashValue = 0
    for (let i = 0; i < message.length; i++) {
        let charCode = message.charCodeAt(i);
        hashValue = ((hashValue << 5) - hashValue) + charCode;
        hashValue = hashValue & hashValue; // Ensure 32-bit integer limit
    }

    hashValue = hashValue >>> 0; // Convert to unsigned 32-bit integer
    console.log(hashValue.toString(16));
    return hashValue.toString(16);
}