<template>
  <router-view/>
</template>

<style lang="postcss">

html, body, #app {
  @apply h-full;
  @apply w-full;
  @apply bg-gray-100;
  @apply font-sans;
}
#app {
  @apply fixed;
  @apply top-0;
  @apply left-0;
  @apply overflow-hidden;
}
</style>
