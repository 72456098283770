<template>
  <div class="bg-white rounded-lg py-4 pl-4">
    <span class="font-bold text-xl">Verfügbare Karten</span><br />
    <div
      v-for="card in cards"
      :key="card.id"
      class="w-80 mr-4 shadow-lg border-4 rounded-lg my-4 px-4 pb-4 text-center inline-block align-top"
    >
      <span class="font-bold text-lg">{{ card.project }}</span>
      <div class="w-full text-left">
        <span><b>Name: </b>{{ card.name }}</span>
      </div>
      <div v-if="currentQR.contract == card.id">
        <span class="w-full text-left">Ablauf: {{ currentQR.validTo }}</span>
        <div class="relative w-full">
          <div class="dotr rounded-full"></div>
        </div>
        <QR
          :value="currentQR.code"
          :size="325"
          class="inline-block mt-3"
          @click="showQR = false"
        ></QR>
        <div class="relative w-full">
          <div class="dotl rounded-full"></div>
        </div>
      </div>
      <button class="mt-4" @click="generateQRCode(card)">
        QR-Code neu generieren
      </button>
    </div>
  </div>
</template>
<style scoped>
.dotr,
.dotl {
  width: 10px;
  height: 10px;
  background-color: green;
  position: absolute;
  top: 50%;
  animation: moveRight 2s infinite alternate;
}
.dotl {
  animation: moveLeft 2s infinite alternate;
}

@keyframes moveRight {
  from {
    left: 0;
  }

  to {
    left: 100%;
  }
}
@keyframes moveLeft {
  to {
    left: 0;
  }

  from {
    left: 100%;
  }
}
</style>
<script>
// @ is an alias to /src
import QR from "@/lib/QR.js";
import API from "@/API";
export default {
  name: "Cards",
  components: {
    QR,
  },
  data() {
    return {
      showQR: false,
      currentQR: {},
    };
  },
  props: {
    cards: Array,
  },
  methods: {
    generateQRCode(card) {
      API.getQRCode(card.id, (r, status) => {
        if (status === 200) {
          this.currentQR = {};
          setTimeout(() => {
            this.currentQR = r;
            this.currentQR.validTo = new Date(
              this.currentQR.validTo
            ).toLocaleTimeString("de-DE");
          }, 200);
        }
      });
    },
  },
};
</script>
  